.sheen {
  position: relative;
  overflow: hidden;
}

.sheen:after {
  animation: sheen 1s 0s forwards;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.5) 50%, transparent);
  transform: rotateZ(60deg) translate(-1em, 4em);
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(0, -4em);
  }
}

.start-now, .start-now:after {
  animation-delay: 0;
}

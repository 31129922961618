.progress-button-container {
    border-radius: 10px;
    border: none;
    overflow: hidden;
    position: relative;
    height: auto;
}

.progress-container {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;

    border-radius: 10px;
    border: 2px white;
    overflow: hidden;
}

.progress-title-container {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    bottom: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: transparent;
}


.progress-title {
    margin: 0px;
    padding: 20px 20px 20px 20px;

    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

.progress-value {
    margin: 0px;
    padding: 10px 0px 10px 0px;

    font-size: 60px;
    font-weight: normal;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

/* Grid version commented out */

/* .grid-container-home {
    display: grid;
    background-image: linear-gradient(to bottom, #111111 0%, #1C2025 100%);
    height: 100vh;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    overflow: auto;
    align-items: center;
    grid-template-rows: 45px auto 90px;
    grid-gap: 5px;
    -ms-grid-rows: 50px 1fr 90px;
} */

/* .grid-item-home {
    margin: auto;
}

.grid-item-home.header {
    margin: 0;
  } */


/* .grid-container-home h2 {
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin: 0 auto 1rem;
} */

/*
.grid-item-bottom {
  grid-row: 3 / 4;
  -ms-grid-row: 3 / 4;
} */

.bottom_row_fixed {
    bottom: 0;
    left: 0;
    right:0;
    position: fixed;
    width: 100%;
    height: 100px;
    touch-action: manipulation;
}

li {
    padding: 0px 1px 0px 1px;
}

.bounce {
  animation: bounce 1s linear;
  -webkit-animation: bounce 1s linear;
  -moz-animation: bounce 1s linear;
  -o-animation: bounce 1s linear;
}

.answerItemClass{
    margin-left:1px;
    margin-right:1px;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


/* Flexbox version */
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
  zoom: 45%; }

.card {
    box-shadow: unset !important;
    transition: 0.3s;
    background-color: transparent !important;
    color:white;
    margin: 10px;
    padding: 10px;
    border: none !important;
}

.flex-container-home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.flex-header-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.header-left, .header-right {
  padding: 10px;
}

.header-left img {
  width: 35px;
}

.flex-content-container-home {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}

.intro-container-home {
    margin: 0 auto auto;
    text-align: center;
}

.scratch-header-home {
  max-width: 280px;
  margin: auto;
}

.scratch-image-container {
    margin: 0 auto 2em;
}

.emphasizedText {
  background-color: #000;
  font-size: 5vw;
  padding-left: 20px;
  font-family: "Oswald", sans-serif;
  padding-right: 20px;
  font-weight: 700;
}

.btn.btn-play {
  margin-top: 10px;
  color: #fff;
  background-color: #fff;
  width: 250px;
  font-size: 1.4em;
  font-family: "Oswald", sans-serif;
  height: 50px;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules {
  margin-top: 15px;
  color: #fff;
  background-color: #000;
  min-width: 120px;
  /* width: 120px; */
  font-size: 15px;
  font-family: "Oswald", sans-serif;
  height: 40px;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-next {
    color: #fff;
    background-color: #EDAA37;
    width: 250px;
    font-size: 1.4em;
    height: 50px;
    font-weight: 100;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-next:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, .25);
}

.question-box #create-email-form label {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 300;
}

.question-box {
    background-color: white;
}

.btn.btn-default.btn-admin {
    font-weight: 300;
}

.home-logo-container {
    margin: auto;
    text-align: center;
}

img.home-logo {
    width: 70%;
    max-width: 400px;
    margin-bottom: 10%;
}

.home-logo-title {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15%;
    transform: scaleY(1.6);
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 0;
}


@media (min-width: 600px) {
  .intro-container-home, .scratch-container-home {
    margin: 5% auto auto;
  }
}

@media  (max-width: 600px) {
  .scratch-image-container {
    margin: 0 auto 1em;
  }

  .flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-size: 1.5em;
  }

  .emphasizedText {
    font-size: 10vw;
  }
  .hero-text-container.hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
      zoom: 40%;
  }
    .answerItemClass{
        margin:0px
    }
}

@media (min-width: 767px) {
  .scratch-header-home h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    line-height: 1.1;
  }
  .scratch-image-container {
    margin: 0 auto 30px;
  }
}

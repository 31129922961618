body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html {
  scrollbar-width: none;
}

